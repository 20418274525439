<template>
  <main class="w-full md:w-11/12 xl:w-11/12 mx-auto bg-white">
    <div v-if="!isEdit" class="w-full px-4 py-8">
      <div class="w-full flex items-center justify-between">
        <h4 class="text-2xl md:text-3xl font-medium">Account Information</h4>
        <div class="flex items-center gap-2">
          <img class="w-3 md:hidden" src="@/assets/icons/switch.svg" alt="" />
          <span
            @click="addBank = true"
            class="md:w-auto flex items-center justify-center gap-3 font-medium cursor-pointer text-center bg-white  text-dark-800 rounded-full border border-primary px-3 text-sm py-3"
          >
            <img class="w-3" src="@/assets/icons/newPlus1.svg" alt="" />

            <span class="md:flex hidden items-center text-sm text-primary">
              Add Account
            </span></span
          >
        </div>
      </div>

      <hr class="text-grey my-4" />

      <div
        v-if="companyBankAccounts && companyBankAccounts.length"
        class="flex flex-col gap-4"
      >
        <div
          v-for="bank in companyBankAccounts"
          :key="bank._id"
          class="border border-primary  rounded-xl relative overflow-hidden w-full p-6 flex flex-col md:flex-row justify-center items-center gap-4"
        >
          <div class="w-full flex flex-wrap items-start gap-2">
            <div
              class="w-full flex flex-col md:flex-col flex-wrap items-start gap-2"
            >
              <div class="flex flex-row items-center gap-x-2">
                <span class="text-md text-newLightgrey">Bank Name:</span>
                <span
                  class="flex items-center gap-2 text-sm md:text-lg text-dark-800 font-medium"
                >
                  <div
                    class="w-5 h-5 md:w-8 md:h-8 rounded-full border border-dark-100 overflow-hidden"
                  >
                    <img :src="bankUrl(bank.bankName).logo" />
                  </div>

                  {{ bank.bankName }}</span
                >
                <div
                  @click="toggleShow(bank._id)"
                  class="absolute right-4 cursor-pointer z-50 w-4"
                >
                  <img src="@/assets/icons/more-icon.svg" alt="" />
                </div>
                <div
                  v-show="show && showId === bank._id"
                  class="w-40 group-hover:text-dark-800 flex absolute right-8 mt-20 flex-col z-50 bg-white shadow-lg rounded-xl"
                >
                  <div
                    class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                  >
                    <span
                      class="hover:bg-newLightGreen text-black py-2 px-4 w-full rounded-t-xl"
                      @click="changeBank(bank._id)"
                    >
                      Change Account
                    </span>

                    <span
                      @click="(deleteWarning = true), (removeBankId = bank._id)"
                      class="hover:bg-newLightGreen text-black rounded-b-xl py-2 px-4 w-full"
                      >Remove</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex flex-row items-center gap-x-2">
                <span class="text-md text-dark-600">Account Number:</span>
                <span class="text-sm md:text-lg text-dark-800 font-medium">{{
                  bank.accountNumber
                }}</span>
              </div>
            </div>
            <div class="w-full flex flex-row items-center gap-x-2">
              <span class="text-md text-dark-600">Account Name:</span>
              <span class="text-sm md:text-lg text-dark-800 font-medium">{{
                bank.accountName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center">No Bank Accounts Found</div>

      <hr class="my-6 border border-newLightgrey" />
    </div>

    <div v-else class="w-full px-6 py-10 relative">
      <span
        @click="isEdit = false"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </span>

      <BankDetail
        user="admin"
        @close="isEdit = false"
        @cancel="isEdit = false"
      />
    </div>

    <easiImageView
      :visible="visibleRef"
      :imgs="imgsRef"
      @hide="onHide"
    ></easiImageView>

    <div
      @click="show = false"
      :class="!show ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal v-if="addBank" @close="addBank = false" :isHeader="false">
      <BankDetail :type="true" user="admin" @close="addBank = false" />
    </easiModal>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to remove bank?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              @click="deleteWarning = false"
              class="w-40 min-w-full"
              :class="deleteLoading ? 'pointer-events-none' : ''"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              @click="deleteBank"
              class="w-40 min-w-full"
              :loading="deleteLoading"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Bank account deleted successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import UploadComponent from "@/components/global/UploadComponent";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import NigerianStates from "@/utils/states";
import IndustryList from "@/utils/Industry";

import banks from "@/utils/banks";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();

const { processDate, validatePhone, validateEmail, log } = helperFunctions;
const visibleRef = ref(false);
const imgsRef = ref("");
const companyAccount = computed(() => store.getCompanyAccountDetails);

// const companyBankAccounts = [];
const companyBankAccounts = computed(() => store.getAllBanks);

const bankUrl = (bankName) => {
  if (bankName) {
    console.log("Bank has name");
    const data = banks.filter((x) => {
      return bankName.toLowerCase().indexOf(x.name.toLowerCase()) > -1 ? x : "";
    });
    return data[0];
  } else {
    console.log("Bank has no name");
    return {
      logo: "",
    };
  }
};

const deleteWarning = ref(false);
const addBank = ref(false);
const show = ref(false);
const showId = ref("");
const loading = ref(false);
const deleteLoading = ref(false);
const updateSuccess = ref(false);
const isEdit = ref(false);
// const companyData = computed(() => store.getCompanyDetails);
const errorRules = ref({
  phone: "",
  workEmail: "",
  otherPhoneNumbers: "",
  businessName: "",
});
const removeBankId = ref("");
const imgLink = ref("@/assets/img/cert.png");
const industryData = IndustryList.map((el) => el.name);

const args = reactive({
  businessName: "",
  officeAddress: "",
  email: "",
  phone: "",
  stateForPaye: "",
  companySize: "",
  payeId: "",
  nhfCode: "",
  websiteUrl: "",
  industry: "",
});

const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  return stateArray;
});
const updateProfile = () => {
  console.log(args);
};

const bankLogo = computed(() => {
  let bank = banks.find((el) => el.code === "063");

  return bank.logo;
});

const toggleShow = (id) => {
  if (show.value) {
    show.value = false;
    showId.value = "";
  } else {
    show.value = true;
    showId.value = id;
  }
};
const changeBank = (id) => {
  console.log(id);
  window.localStorage.setItem("bankId", id);
  isEdit.value = true;
};

const onPreview = (img) => {
  console.log(img);
  imgsRef.value = img;
  visibleRef.value = true;
};

const onHide = () => (visibleRef.value = false);

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};

const deleteBank = async () => {
  console.log(removeBankId.value);
  deleteLoading.value = true;
  try {
    mutate({
      endpoint: "DeleteBankAccount",
      service: "SETTINGS",
      data: { bankAccountId: removeBankId.value },
    })
      .then((res) => {
        if (res.success) {
          deleteLoading.value = false;
          updateSuccess.value = true;
          deleteWarning.value = false;

          queryBankAccounts();

          setTimeout(() => {
            updateSuccess.value = false;
            // window.location.reload();
          }, 500);
          // toast.success("Bank Record Updated Successfully");
        }
      })
      .catch((e) => {
        console.log(e);
        deleteLoading.value = false;
      });
  } catch (e) {
    deleteLoading.value = false;
    console.log(e);
  }
};

async function queryBankAccounts() {
  loading.value = true;
  try {
    await query({
      endpoint: "FetchBankAccounts",
      service: "SETTINGS",
      storeKey: "allBanks",
    });

    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

queryBankAccounts();
queryCompanyAccountDetails();

console.log(companyBankAccounts.value);
</script>

<style>
.color {
  color: #b9bbc07d;
  color: #e0e0e08b;
}
</style>
