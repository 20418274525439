<template>
  <main
    class="flex w-full md:m-0 md:bg-white rounded-r-xl overflow-hidden md:px-6 pt-4 md:border gap-4 md:gap-0 border-outlineGray md:py-4 flex-col md:flex-row items-start"
  >
    <!-- <div
      class="bg-white md:bg-transparent rounded-2xl md:rounded-none grid grid-cols-2 md:grid-cols-1 p-2 md:p-0 md:py-5 w-full md:w-56 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        v-show="btn.show"
        :key="i"
        @click="switchView(i)"
        :class="[
          btn.active ? ' text-primary bg-highlight font-semibold shadow-sm ' : 'text-dark-800',
          i == 0 ? 'rounded-l-2xl' : '',
          i == pages.length - 1 ? 'rounded-r-2xl' : '',
        ]"
        class="relative col-span-1 w-full capitalize md:rounded-none border md:border-0 md:border-t border-dark-100 text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap"
      >
        {{ btn.title }}

        <div
          v-if="btn.active"
          style="width: 3px"
          class="hidden md:block absolute right-0 top-0 h-full rounded-l-sm bg-primary"
        ></div>
      </button>
    </div> -->

    <div class="relative shadow-md md:shadow-none rounded-2xl md:rounded-none p-4 w-full text-sm h-auto bg-white">
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted, shallowRef } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

//// View Components ////
import Profile from "@/components/Settings/Profile/Profile.vue";
import Kyc from "@/components/Settings/Profile/KYC/Index.vue";
import AccountInfo from "@/components/Settings/Business/AccountInfo.vue";

const route = useRoute();
const store = useDataStore();
const display = shallowRef(Profile);

const companyDetails = computed(() => store.getCompanyDetails);
const accountType = window.localStorage.getItem("accountType");

const pages = reactive([
  {
    title: "Profile",
    value: "profile",
    component: Profile,
    active: true,
    show: true,
    live: "view",
  },
  {
    title: "Account Information",
    value: "information",
    component: AccountInfo,
    active: false,
    show: companyDetails.value && companyDetails.value.registrationType !== "COMPANY",
    live: "view",
  },
  // {
  //   title: "KYC Verification",
  //   value: "kyc",
  //   component: Kyc,
  //   active: false,
  //   show: true,
  //   live: "view",
  // },
]);

onMounted(() => {
  if (route.query && route.query.from && route.query.from === "dashboard") {
    const index = pages.findIndex((item) => item.value === "kyc");
    switchView(index);
  }
});

const switchView = (index) => {
  display.value = pages[index].component;

  // store.$patch({
  //   pageIndex: index,
  // });

  pages.forEach((el) => {
    el.active = false;
  });

  pages[index].active = true;
};
</script>

<style></style>
